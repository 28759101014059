<template>
  <div style="height: calc(100% - 20px)">
    <div class="all">
      <div class="title">推广记录</div>
      <div>
        <table v-for="(item, index) in tuiList" :key="index">
          <tr>
            <th>记录号</th>
            <th>用户名</th>
            <th>订单数量</th>
          </tr>
          <tr>
            <td>{{ item.id }}</td>
            <td>{{ item.memberName }}</td>
            <td>{{ item.amount }}</td>
          </tr>
        </table>
        <div class="more">
          <span class="moreShu">没有更多数据了</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { htmlLoginAndRegist } from "../../utils/apis/apis.js";
export default {
  name: "Extension",
  data() {
    return {
      tuiId: "",
      tuiList: [],
    };
  },
  created() {
    this.tuiId = this.$route.params.id;
    this._getList();
  },
  mounted() {},
  methods: {
    _getList() {
      const params = {
        pageNum: 1,
        pageSize: 1
      };
      this.$reqPost(htmlLoginAndRegist.getWxExtension, params).then((res) => {
        if (res.data.code === 200) {
          this.tuiList = res.data.data.list;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import url("./order.less");
.title {
  margin-bottom: 10px;
  padding-left: 10px;
  font-size: 18px;
  font-weight: bold;
  border-left: 1px solid rgb(255, 196, 0);
}
.all {
  height: 100%;
  padding: 10px;
  background: white;
}
table {
  width: 100%;
  word-wrap: break-word;
  word-break: break-all;
  text-align: left;
  border-bottom: 1px solid #dddddd;
  border-left: 1px solid #dddddd;
  th {
    padding: 5px;
    font-weight: normal;
    border-top: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
  }
  td {
    padding: 5px;
    border-top: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    word-break: wrap;
    min-width: 80px;
  }
}
.more {
  text-align: center;
  margin-top: 20px;
}
.moreShu {
  color: #999;
}
</style>
