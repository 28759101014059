// 获取accesstoken
export const accessToken = {
  getAccessToken: '/appLogin/getAccessToken',
  getWechatUrl: '/appLogin/getWxAddressLogin',
  getWechatUrl1: '/appLogin/getWxAddressLogin1',
  getWeiXinInfo: '/appLogin/url',
  upDateUserInfo: '/member-manage/updateUserName',
  getCurrentQecode: '/rake-back-manage/getRakeReturnQrcode'
}
// 根据会员id 获取优惠券
export const yhq = {
  getYhqByUserId: '/member-coupons/getMyCouponsById'
}
// 网页登录
export const htmlLoginAndRegist = {
  login: '/appLogin/webUserLogin',
  regist: '/appLogin/webRegistreUser',
  getMemberInfo: '/appLogin/getMemeberInfo',
  getExtension: '/rake-back-manage/getRakeReturnByMemberList',
  getWxExtension: '/rake-back-manage/getRakeReturnByMemberListByWX',
  getPic: '/rake-back-manage/getRakeReturnQrcode',
  getPermanentPic: '/rake-back-manage/getPermanentRakeReturnQrcode'
}
// 地址簿管理
export const addressInfo = {
  list: '/address-book-manage/getMemberAddressList',
  nationList: '/base-dict/getAddressNations',
  add: '/address-book-manage/addMemberAddress',
  item: '/address-book-manage/getById',
  update: '/address-book-manage/updateMemberAddress',
  delete: '/address-book-manage/delete'
}


//  运费估算
export const yunfee = {
  info: '/charge-mode/getFreightChargeMode'
}

// //  运费估算
// export const yunfee = {
//   info:'/charge-mode/getFreightChargeMode'
// }
// 积分商城
export const jifen = {
  list: '/integral-goods/getWXIntegralGoodsList',
  item: '/integral-goods/getById/',
  duihuan: '/integral-goods/memberUserExchangeGoods'
}

// 用户安全
export const userSafe = {
  changePassword: '/member-manage/updatePasswordByMember',
  contact: '/member-manage/updatePasswordByMemberTel'
}

export const webLog = {
  addLog: '/log-manage/addLog'
}